import './languagetoggle.scss';

const LanguageToggle = ({selectedLanguage, setSelectedLanguage}) => {

  return (
    <div className='languagetoggle'>
      <span
        onClick={() => setSelectedLanguage('EN')}
        className={selectedLanguage === 'EN' ? 'selected' : ''}
      >
        EN
      </span>
      <span className="separator">{' | '}</span>
      <span
        onClick={() => setSelectedLanguage('DE')}
        className={selectedLanguage === 'DE' ? 'selected' : ''}
      >
        DE
      </span>
      <span className="separator">{' | '}</span>
      <span
        onClick={() => setSelectedLanguage('CN')}
        className={selectedLanguage === 'CN' ? 'selected' : ''}
      >
        中
      </span>
    </div>
  );
};

export default LanguageToggle;