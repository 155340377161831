import "./intro.scss"
import siteData from "../Data.json"

export default function Intro({selectedLanguage}) {

  let displayData = {};
  if (selectedLanguage === "EN") {
    displayData = siteData.en.intro;
  } else if (selectedLanguage === "DE") {
    displayData = siteData.de.intro;
  } else if (selectedLanguage === "CN") {
    displayData = siteData.cn.intro;
  }

  return (
    <div className='intro' id="intro">
        <div className="left">
          <div className="left_1">{displayData.left_1}</div>
          <div className="left_2">{displayData.left_2}</div>
          <div className="left_2">{displayData.left_3}</div>
        </div>
        <div className="right">
          <div className="right_1">{displayData.right_1}</div>
          <div className="right_2">{displayData.right_2}</div>
        </div>
    </div>
  )
}
