import "./topbar.scss";
import LanguageToggle from "./Languagetoggle";
import Burgerbutton from "./Burgerbutton";
import siteData from "../Data.json"

export default function Topbar({selectedLanguage, setSelectedLanguage, sidebarOpen, setSidebarOpen}) {

  let displayData = {};
  if (selectedLanguage === "EN") {
    displayData = siteData.en.topbar;
  } else if (selectedLanguage === "DE") {
    displayData = siteData.de.topbar;
  } else if (selectedLanguage === "CN") {
    displayData = siteData.cn.topbar;
  }

  return (
    <div className="topbar">
        <div className="wrapper">
            <div className="left">
                <a href="#intro" className="logo">Oasis.</a>
                <div className="itemContainer">
                  <a href="#services">{displayData.services}</a>
                  <a href="#why">{displayData.why}</a>
                  <a href="#contact">{displayData.contact}</a>
                </div>
            </div>
            <div className="right">
              <LanguageToggle className="languagetoggle"
                selectedLanguage={selectedLanguage}
                setSelectedLanguage={setSelectedLanguage}
              />
              <Burgerbutton
                sidebarOpen={sidebarOpen}
                setSidebarOpen={setSidebarOpen}
              />
            </div>
        </div>
    </div>
  )
};
