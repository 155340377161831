import React, { useEffect, useState} from 'react';
import Topbar from "./components/topbar/Topbar";
import Sidebar from "./components/sidebar/Sidebar";
import Intro from "./components/intro/Intro";
import Services from "./components/services/Services";
import Why from "./components/why/Why";
import Contact from "./components/contact/Contact";
import "./app.scss";

const App = () => {

  const [selectedLanguage, setSelectedLanguage] = useState('EN');
  const [sidebarOpen, setSidebarOpen] = useState(false);

  useEffect(() => {
    function appHeight() {
      const doc = document.documentElement;
      doc.style.setProperty('--vh', (window.innerHeight * 0.01) + 'px');
    }

    window.addEventListener('resize', () => appHeight);
    appHeight();
    
    return () => {
      window.removeEventListener('resize', appHeight);
    }
  }, []);

  useEffect(() => {
    const browserLanguage = navigator.language;
    if (browserLanguage.startsWith('de')) {
      setSelectedLanguage('DE');
    } else if (browserLanguage.startsWith('zh')) {
      setSelectedLanguage('CN');
    } else {
      setSelectedLanguage('EN');
    }
  }, []);

  return (
    <div className="App">
      <Topbar
        selectedLanguage={selectedLanguage}
        setSelectedLanguage={setSelectedLanguage}
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
      />
      <Sidebar
        selectedLanguage={selectedLanguage}
        setSelectedLanguage={setSelectedLanguage}
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
      />
      <div className="sections">
        <Intro selectedLanguage={selectedLanguage}/>
        <Services selectedLanguage={selectedLanguage}/>
        <Why selectedLanguage={selectedLanguage}/>
        <Contact selectedLanguage={selectedLanguage}/>
      </div>
    </div>
  )
};

export default App;