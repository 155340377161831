import "./contact.scss";
import siteData from "../Data.json";
import {ReactComponent as EmailLogo} from "./email.svg";
import {ReactComponent as PhoneLogo} from "./phone.svg";
import {ReactComponent as LinkedinLogo} from "./linkedin.svg";

export default function Contact({selectedLanguage}) {

  let displayData = {};
  if (selectedLanguage === "EN") {
    displayData = siteData.en.contact;
  } else if (selectedLanguage === "DE") {
    displayData = siteData.de.contact;
  } else if (selectedLanguage === "CN") {
    displayData = siteData.cn.contact;
  }

  return (
    <div className='contact' id="contact">
      <div className="title">
        {displayData.title}
      </div>
      <div className="wrapper">
        <a href={displayData.email_url} className="box-link" target="_blank" rel="noopener noreferrer">
          <div className="box">
            <EmailLogo className="logo"/>
            <div className="text-wrapper">
              <div className="headline">
                <span>{displayData.email_title}</span>
              </div>
              <div className="text">{displayData.email_text}</div>
            </div>
          </div>
        </a>
        <a href={displayData.phone_url} className="box-link" target="_blank" rel="noopener noreferrer">
          <div className="box">
            <PhoneLogo className="logo"/>
            <div className="text-wrapper">
              <div className="headline">
                <span>{displayData.phone_title}</span>
              </div>
              <div className="text">{displayData.phone_text}</div>
            </div>
          </div>
        </a>
        <a href={displayData.linkedin_url} className="box-link" target="_blank" rel="noopener noreferrer">
          <div className="box">
            <LinkedinLogo className="logo"/>
            <div className="text-wrapper">
              <div className="headline">
                <span>{displayData.linkedin_title}</span>
              </div>
              <div className="text">{displayData.linkedin_text}</div>
            </div>
          </div>
        </a>
      </div>
    </div>
  )
};