import siteData from "../Data.json";
import "./why.scss";
import {ReactComponent as ExperienceLogo} from "./experience.svg";
import {ReactComponent as ProjectLogo} from "./project.svg";
import {ReactComponent as AwardLogo} from "./award.svg";
import {ReactComponent as GritLogo} from "./grit.svg";

export default function Why({selectedLanguage}) {

    let displayData = {};
    if (selectedLanguage === "EN") {
        displayData = siteData.en.why;
    } else if (selectedLanguage === "DE") {
        displayData = siteData.de.why;
    } else if (selectedLanguage === "CN") {
        displayData = siteData.cn.why;
    }

    return (
    <div className='why' id="why">
        <div className="title">{displayData.title}</div>
        <div className="grid">
            <div className="box">
                <ExperienceLogo className="logo"/>
                <div className="wrapper">
                    <div className="box-title">{displayData.box_1_title}</div>
                    <div className="box-text">{displayData.box_1_text}</div>
                </div>
            </div>
            <div className="box">
                <ProjectLogo className="logo"/>
                <div className="wrapper">
                    <div className="box-title">{displayData.box_2_title}</div>
                    <div className="box-text">{displayData.box_2_text}</div>
                </div>
            </div>
            <div className="box">
                <AwardLogo className="logo"/>
                <div className="wrapper">
                    <div className="box-title">{displayData.box_3_title}</div>
                    <div className="box-text">{displayData.box_3_text}</div>
                </div>
            </div>
            <div className="box">
                <GritLogo className="logo"/>
                <div className="wrapper">
                    <div className="box-title">{displayData.box_4_title}</div>
                    <div className="box-text">{displayData.box_4_text}</div>
                </div>
            </div>
        </div>
    </div>
    )
}