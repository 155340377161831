import "./services.scss";
import siteData from "../Data.json";
import {ReactComponent as DataBaseLogo} from "./database.svg";
import {ReactComponent as DashboardLogo} from "./dashboard.svg";
import {ReactComponent as TrainingLogo} from "./training.svg";

export default function Services({selectedLanguage}) {

  let displayData = {};
  if (selectedLanguage === "EN") {
    displayData = siteData.en.services;
  } else if (selectedLanguage === "DE") {
    displayData = siteData.de.services;
  } else if (selectedLanguage === "CN") {
    displayData = siteData.cn.services;
  }

  return (
    <div className="services" id="services">
      <div className="left">
        <div className="left-wrapper">
          <div className="left_1">{displayData.left_1}</div>
          <div className="left_2">{displayData.left_2}</div>
        </div>
        <div className="horizontal_line"></div>
        <div className="left_3">{displayData.left_3}</div>
      </div>
      <div className="right">
        <div className="wrapper">
          <div>
            <DataBaseLogo className="logo"/>
            <div className="right-wrapper">
              <div className="title">{displayData.right_1}</div>
              <div className="subtitle">{displayData.right_2}</div>
              <div className="text">{displayData.right_3}</div>
            </div>
          </div>
          <div>
            <DashboardLogo className="logo"/>
            <div className="right-wrapper">
              <div className="title">{displayData.right_4}</div>
              <div className="subtitle">{displayData.right_5}</div>
              <div className="text">{displayData.right_6}</div>
            </div>
          </div>
          <div>
            <TrainingLogo className="logo"/>
            <div className="right-wrapper">
              <div className="title">{displayData.right_7}</div>
              <div className="subtitle">{displayData.right_8}</div>
              <div className="text">{displayData.right_9}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};