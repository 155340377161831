import './burgerbutton.scss';

const BurgerButton = ({sidebarOpen, setSidebarOpen}) => {

    return (
        <button 
            className={`burger ${sidebarOpen ? 'active' : ''}`} 
            onClick={() => setSidebarOpen(!sidebarOpen)}
        >
        </button>
    );
};

export default BurgerButton;