import "./sidebar.scss";
import siteData from "../Data.json";
import LanguageToggle from "../topbar/Languagetoggle";

export default function Sidebar({selectedLanguage, setSelectedLanguage, sidebarOpen, setSidebarOpen}) {

  let displayData = {};
  if (selectedLanguage === "EN") {
    displayData = siteData.en.topbar;
  } else if (selectedLanguage === "DE") {
    displayData = siteData.de.topbar;
  } else if (selectedLanguage === "CN") {
    displayData = siteData.cn.topbar;
  }

  return (
    <div className={"sidebar " + (sidebarOpen && "active")}>
      <div className="wrapper">
        <a href="#intro" onClick={() => setSidebarOpen(!sidebarOpen)}>{displayData.intro}</a>
        <a href="#services" onClick={() => setSidebarOpen(!sidebarOpen)}>{displayData.services}</a>
        <a href="#why" onClick={() => setSidebarOpen(!sidebarOpen)}>{displayData.why}</a>
        <a href="#contact" onClick={() => setSidebarOpen(!sidebarOpen)}>{displayData.contact}</a>
      </div>
      <LanguageToggle
      selectedLanguage={selectedLanguage}
      setSelectedLanguage={setSelectedLanguage}
      className="languagetoggle"/>
    </div>
  )
}
